<template>
    <div class="landing-screen-container">
        <!-- Add the Logo image above the input fields -->
        <img src="@/assets/yellow_logo.png" alt="Logo" class="logo" />

        <form @submit.prevent="handleSubmit">
            <label for="eventId" class="label">Event ID</label>
            <input type="text" id="eventId" v-model="eventId" class="input-field" placeholder="Enter Event ID" required />

            <label for="verifierId" class="label">Verifier ID</label>
            <input type="text" id="verifierId" v-model="verifierId" class="input-field" placeholder="Enter Verifier ID" required />

            <button type="submit" class="submit-button">Submit</button>
        </form>
        <div v-if="statusMessage" class="status-message">{{ statusMessage }}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            eventId: "",
            verifierId: "",
            statusMessage: "",
        };
    },
    methods: {
        async handleSubmit() {
            this.statusMessage = "Submitting...";
            try {
                const response = await fetch("https://api.countersbd.com/api/v1/ticket-verify/validate-verifier", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ eventId: this.eventId, verifierId: this.verifierId }),
                });
                if (response.ok) {
                    const result = await response.json();
                    this.statusMessage = result.message;

                    // Save data to cookies
                    document.cookie = `verifierId=${this.verifierId}; path=/; max-age=86400; SameSite=Strict`;
                    document.cookie = `eventId=${result.data.eventId}; path=/; max-age=86400; SameSite=Strict`;
                    document.cookie = `eventName=${result.data.eventName}; path=/; max-age=86400; SameSite=Strict`;
                    document.cookie = `categoryId=${result.data.categoryId}; path=/; max-age=86400; SameSite=Strict`;
                    document.cookie = `categoryName=${result.data.categoryName}; path=/; max-age=86400; SameSite=Strict`;

                    // Redirect to scan QR screen
                    this.$router.push({ name: 'ScanQR' });
                } else {
                    this.statusMessage = "Error processing request. Please try again.";
                }
            } catch (error) {
                console.error("API call error:", error);
                this.statusMessage = "Network error. Please try again.";
            }
        }
    },
};
</script>

<style scoped>
.landing-screen-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 16px;
    margin-top: -20px; /* Use a negative value to reduce top space */
}

.logo {
    width: 100%; /* Make the logo take full width */
    max-width: 300px; /* Set a max width to prevent it from becoming too large */
    height: auto; /* Maintain the aspect ratio */
    margin-bottom: 20px; /* Space between logo and form */
    margin-top: 0; /* Remove margin above the logo */
}

.label {
    font-size: 1em;
    margin-top: 16px;
    margin-bottom: 8px;
}

.input-field {
    width: 100%;
    padding: 12px 16px;
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.status-message {
    margin-top: 16px;
    font-size: 1em;
    color: #333;
    text-align: center;
}

button {
    width: 100%;
    background-color: #D82128;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-sizing: border-box;
}

button:hover {
    background-color: #b82024;
}

button:active {
    background-color: #a51a1e;
}
</style>
