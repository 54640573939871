// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import LandingScreen from '../components/LandingScreen.vue';
import ScanQR from '../components/ScanQR.vue';

const routes = [
    { path: '/', name: 'LandingScreen', component: LandingScreen },
    { path: '/scan-qr', name: 'ScanQR', component: ScanQR },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
