<template>
  <div id="app" class="app-container">
    <router-view /> <!-- This will render the current route component -->
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
.app-container {
  background-color: #1E293C; /* Set background color */
  min-height: 100vh; /* Ensure it covers the full height */
  color: #ffffff; /* Optional: change text color for contrast */
}
</style>
