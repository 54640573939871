<template>
    <div class="scan-qr-container">
        <h2>Scan Ticket</h2>

        <!-- Labels for the event information -->
        <label for="eventName">Event Name: {{ eventName }}</label>
        <label for="categoryName">Category Name: {{ categoryName }}</label>

        <div id="qr-reader" class="qr-reader"></div>
        <button @click="goBackToHome" class="back-button">Back to Home</button>

        <!-- Modal for showing scanned QR code -->
        <div v-if="showModal" :class="['modal-overlay']">
            <div class="modal-content" :class="modalClass">
                <h3 v-if="isSuccess">{{ modalTitle }}</h3>
                <p v-if="isSuccess" class="success-message">{{ modalMessage }}</p>
                <p v-if="!isSuccess">{{ modalMessage }}</p>
                <button @click="closeModal" class="close-button">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
import { Html5Qrcode } from "html5-qrcode";

export default {
    data() {
        return {
            qrCodeReader: null,
            isScannerActive: false,
            eventId: this.getCookie('eventId'),
            verifierId: this.getCookie('verifierId'),
            eventName: this.getCookie('eventName'),
            categoryId: this.getCookie('categoryId'),
            categoryName: this.getCookie('categoryName'),
            showModal: false,
            scannedCode: '',
            isSuccess: false,
            modalTitle: '',
            modalMessage: '',
            modalClass: '',
        };
    },
    methods: {
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        },
        startScanner() {
            const config = { fps: 10, qrbox: 250 };
            this.qrCodeReader = new Html5Qrcode("qr-reader");

            this.qrCodeReader.start(
                { facingMode: "environment" },
                config,
                (decodedText, decodedResult) => {
                    this.handleQRCodeSuccess(decodedText, decodedResult);
                },
                (errorMessage) => {
                    console.error("QR Code scan error:", errorMessage);
                }
            ).then(() => {
                this.isScannerActive = true;
            }).catch(error => {
                console.error("Failed to start QR code scanner:", error);
                this.isScannerActive = false;
            });
        },
        handleQRCodeSuccess(decodedText, decodedResult) {
            console.log("Decoded Result:", decodedResult); // Log the result for debugging
            this.scannedCode = decodedText; // Store the scanned code
            this.stopScanner(); // Stop the scanner after a successful scan

            // Call the API to verify the ticket
            this.verifyTicket(this.scannedCode);
        },
        async verifyTicket(ticketId) {
            const apiUrl = "https://api.countersbd.com/api/v1/ticket-verify/verify-ticket";
            const requestBody = {
                eventId: this.eventId,
                verifierId: this.verifierId,
                ticketId: ticketId,
            };

            try {
                const response = await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(requestBody),
                });

                if (response.ok) {
                    this.isSuccess = true; // Mark as successful verification
                    this.modalTitle = ""; // Clear title for success
                    this.modalMessage = "Ticket verified successfully."; // Set success message
                    this.modalClass = "modal-success"; // Class for green modal
                } else {
                    this.isSuccess = false; // Mark as unsuccessful verification
                    const errorResponse = await response.json(); // Parse the error response
                    this.modalTitle = "Verification Failed";
                    this.modalMessage = errorResponse.message; // Set the error message from the response
                    this.modalClass = "modal-error"; // Class for red modal
                }

                this.showModal = true; // Show the modal
            } catch (error) {
                console.error("Error verifying ticket:", error);
                this.isSuccess = false;
                this.modalTitle = "Verification Error";
                this.modalMessage = "An error occurred while contacting the server.";
                this.modalClass = "modal-error"; // Class for red modal
                this.showModal = true; // Show the modal
            }
        },
        closeModal() {
            this.showModal = false;
            this.startScanner(); // Restart the scanner if desired
        },
        clearCookies() {
            const cookies = document.cookie.split("; ");
            cookies.forEach(cookie => {
                const cookieName = cookie.split("=")[0];
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
            });
        },

        stopScanner() {
            if (this.qrCodeReader && this.isScannerActive) {
                this.qrCodeReader.stop().then(() => {
                    this.isScannerActive = false;
                }).catch(error => {
                    console.error("Failed to stop QR code scanner:", error);
                });
            } else {
                console.warn("Scanner is not running or has already been stopped.");
            }
        },

        // Add this method to handle Back to Home button click
        goBackToHome() {
            this.clearCookies(); // Clear cookies
            this.stopScanner();  // Stop the scanner
            // Redirect to index
            this.$router.push({ name: "LandingScreen" });
        },
    },
    mounted() {
        console.log("Event ID:", this.eventId);
        console.log("Verifier ID:", this.verifierId);
        console.log("Event Name:", this.eventName);
        console.log("Category ID:", this.categoryId);
        console.log("Category Name:", this.categoryName);
        this.startScanner();
    },
    beforeUnmount() {
        if (this.qrCodeReader && this.isScannerActive) {
            this.qrCodeReader.stop().catch(error => console.error("Failed to stop QR code scanner:", error));
        }
    },
};
</script>

<style scoped>
.scan-qr-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    height: 100vh;
    text-align: center;
}

.qr-reader {
    width: 100%;
    max-width: 300px;
    margin-top: 20px;
}

.back-button {
    background-color: #D82128;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 16px;
    margin-top: 20px;
    cursor: pointer;
}

.back-button:hover {
    background-color: #b82024;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

/* Change to modal-specific styles */
.modal-success {
    background-color: green;
    /* Green background for success */
    color: white;
    /* White text for success */
    border: 2px solid green;
    /* Green border for success */
}

.modal-error {
    background-color: red;
    /* Red background for error */
    color: white;
    /* White text for error */
    border: 2px solid red;
    /* Red border for error */
}

.qr-image {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
}

.close-button {
    background-color: white;
    /* Set background to white */
    color: black;
    /* Set text color to black */
    border: 2px solid #D82128;
    /* Optional: Add a border to match the theme */
    border-radius: 4px;
    padding: 10px 16px;
    margin-top: 20px;
    cursor: pointer;
}

.close-button:hover {
    background-color: #D82128;
    /* Change to the theme color on hover */
    color: white;
    /* Change text color to white on hover */
}
</style>
